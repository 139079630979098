import React, { useState } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import ResumeModal from './components/ResumeModal'
import Content from './components/Content'
import logo from './assets/logo.svg';
import './App.scss';

function App() {

  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal(!showModal)

  return (
    <React.Fragment>
      <img src={logo} className="App-logo" alt="logo" />
      <div className="app">
        <Container fluid>
          <Content />

          <Row className="link-section">
            <Col xs={4}>
              <Button
                className="btn-style"
                onClick={toggleModal}
              >
                Resume
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                style={{ marginLeft: '5px' }}
                className="btn-style"
                onClick={() => window.open('https://github.com/cgado12')}
              >
                Github
            </Button>
            </Col>
            <Col xs={4}>
              <Button
                className="btn-style"
                onClick={() => window.open('https://www.linkedin.com/in/csgado/')}
              >
                LinkedIn
               </Button>
            </Col>
          </Row>

          <ResumeModal
            showModal={showModal}
            toggleModal={toggleModal}
          />

        </Container>
      </div >
    </React.Fragment>
  );
}

export default App;
