import React from 'react'
import avatar from '../assets/avatar.png';
import { Row, Col } from 'react-bootstrap';

interface rowColHTML {
  html: React.ReactNode
}

const Content = () => {
  const text = {
    first: 'Hey! My name is:',
    second: 'Chris Salgado',
    third: 'I build full stack web and mobile applications',
    fourth: 'I am a passionate software engineer who is currently' +
      ' working as a full stack developer. Lately I have been working across' +
      ' a series of micro-services that are combined to create a fairly ' +
      'complex system that utilizes features such as CRUD support, users, authentication,' +
      ' group/role management, NATS, and RLS. The toolset I have been using most ' +
      'recently includes: javascript(react), golang, and postgres. I am also' +
      ' significantly versed with typescript, graphql, docker, and aws. ' +
      'My time is well spent between building, tinkering, traveling, gaming, and action sport hobbies.' +
      ' Please consider using LinkedIn to contact me!'
  }

  const RowItem = (child: rowColHTML) => {
    return (
      <Row>
        <Col>
          {child.html}
        </Col>
      </Row>
    )
  }

  return (
    <div className="content-section">
      <div className="avatar-text-container" id="atc">
        <img className="avatar" id="avatarById" src={avatar} />
        <div className="avatar-text" id="avatar-text">
          <RowItem
            html={
              <h5 className="intro">
                {text.first}
              </h5>}
          />
          <RowItem
            html={
              <h5 className="lrg-text name-color">
                {text.second}
              </h5>
            }
          />
          <RowItem
            html={
              <h5 className="lrg-text name-desc-color">
                {text.third}
              </h5>
            }
          />
        </div>
      </div>
      <RowItem
        html={
          <p id="desc" className="description">
            {text.fourth}
          </p>
        }
      />
    </div>
  )
}

export default Content
