import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { pdfjs, Page, Document } from 'react-pdf'

// worker for pdf file
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface ResumeModalProps {
  showModal: Boolean
  toggleModal: () => void
}

const ResumeModal: React.FC<ResumeModalProps> = ({
  showModal,
  toggleModal
}): React.ReactElement => {
  const resume = require('../assets/csalgadoRES.pdf')

  return (
    <Modal
      style={{ width: '100%', overflow: 'scroll' }}
      show={showModal}
      onHide={toggleModal}
      centered={true}
      keyboard={true}
    >
      <Modal.Body>
        <Document
          file={resume}
        >
          <Page pageNumber={1} />
        </Document>

      </Modal.Body>
      <Modal.Footer>
        <Button className="pull-left" variant="secondary" onClick={toggleModal}>
          Close
          </Button>
        <a href={resume} download>
          <Button variant="primary">
            Download
              </Button>
        </a>
      </Modal.Footer>
    </Modal>
  )
}

export default ResumeModal